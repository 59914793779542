import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum HotKey {
    CtrlA = 'KeyA',
    CtrlX = 'KeyX',
    CtrlZ = 'KeyZ',
    CtrlC = 'KeyC',
    CtrlV = 'KeyV',

    Meta = 'Meta',
    Control = 'Control',
    Shift = 'Shift',
    Backspace = 'Backspace',
    Delete = 'Delete',
}

@Injectable({ providedIn: 'root' })
export class HotkeyService {
    private ctrl = false;
    event = new BehaviorSubject<KeyboardEvent | null>(null);

    escape = new EventEmitter<KeyboardEvent>();

    has(keys: HotKey[]): boolean {
        const event = this.event.value;
        if (event) {
            if (this.ctrl) {
                return (
                    (keys as string[]).includes(event.code) ||
                    (keys as string[]).includes(event.key)
                );
            }
            return (keys as string[]).includes(event.key);
        }
        return false;
    }

    trigger(event: KeyboardEvent) {
        if (event.type === 'keydown') {
            if (event.key === HotKey.Control || event.key === HotKey.Meta) {
                this.ctrl = true;
            }
            this.event.next(event);
        } else {
            if (event.key === HotKey.Control || event.key === HotKey.Meta) {
                this.ctrl = false;
            }
            this.event.next(null);
        }

        if (event.key === 'Escape') {
            this.escape.emit(event);
        }
    }
}
