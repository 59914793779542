import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { Debounce } from 'plmt-core-library';

@Injectable({
    providedIn: 'root',
})
export class PortalService {
    component = new BehaviorSubject<TemplatePortal<any> | undefined>(undefined);

    @Debounce(0)
    attach<T>(templateRef: TemplateRef<T>, viewContainerRef: ViewContainerRef) {
        const templatePortal = new TemplatePortal(
            templateRef,
            viewContainerRef,
        );

        this.component.next(templatePortal);
    }

    detach() {
        const component = this.component.value;

        if (component?.isAttached) {
            component.detach();
        }

        this.component.next(undefined);
    }
}
